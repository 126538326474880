/*
 * @Author: zhang gen yuan
 * @Date: 2021-09-16 17:08:16
 * @Descripttion:
 */
"use strict";
import axios from "axios";
import store from "@/store";
import router from "@/router";
import { Toast } from "vant";
import { errorToast } from "./util";

import $loading from "@/components/loading/index.js";
import Vue from "vue";
Vue.use($loading);

class HttpRequest {
  // #baseUrl
  constructor() {

    this.baseUrl = this.getBaseUrl();
    this.withCredentials = false;
    this.timeout = 10000;
  }

  getBaseUrl() {
    return process.env.VUE_APP_BASEURL;
  }

  getConfig() {
    const config = {
      baseURL: this.baseUrl,
      timeout: this.timeout,
      withCredentials: this.withCredentials,
      headers: {
        "Content-Type": "application/json;charset=UTF-8",
      },
    };
    return config;
  }

  getParams(payload) {
    const { method, data } = payload;
    if (["post", "put", "patch", "delete"].indexOf(method) >= 0) {
      payload.data = data;
    } else {
      payload.params = data;
      delete payload.data;
    }
    return payload;
  }

  checkStatus(status) {
    let errMessage = "";
    switch (status) {
      case 400:
        errMessage = "错误请求";
        break;
      case 401:
        errMessage = "未授权，请重新登录";
        break;
      case 403:
        errMessage = "拒绝访问";
        break;
      case 404:
        errMessage = "请求错误,未找到该资源";
        break;
      case 405:
        errMessage = "请求方法未允许";
        break;
      case 408:
        errMessage = "请求超时";
        break;
      case 500:
        errMessage = "服务器端出错";
        break;
      case 501:
        errMessage = "网络未实现";
        break;
      case 502:
        errMessage = "网络错误";
        break;
      case 503:
        errMessage = "服务不可用";
        break;
      case 504:
        errMessage = "网络超时";
        break;
      case 505:
        errMessage = "http版本不支持该请求";
        break;
      case 30002:
        errMessage = "用户不存在";
        break;
      default:
        errMessage = `连接错误`;
    }
    return errMessage;
  }

  // 拦截处理
  setInterceptors(instance, loading = true) {
    const that = this;

    // 请求拦截
    instance.interceptors.request.use(
      (config) => {
        if (loading) {
          // Vue.$loading.show("加载中...");
        }

        if (!navigator.onLine) {
          Toast("请检查您的网络是否正常");
          // 上报错误信息
          if(window.actionlistner){
            window.actionlistner.reportError("404", JSON.stringify(config));
          }
          return Promise.reject("请检查您的网络是否正常");
        }
        
        if (store.state.user.userInfo && store.getters["user/userId"]) {
          if (store.getters["user/flag"]) {
            config.headers["token"] = store.getters["user/userId"];
          } else {
            config.headers["x-login-token"] = store.getters["user/userId"];
          }
        }
        return config;
      },
      (error) => {
        Vue.$loading.hide();
        // 上报错误信息
        if(window.actionlistner){
          window.actionlistner.reportError("404", JSON.stringify(error));
        }

        return Promise.reject(error);
      }
    );

    //响应拦截
    instance.interceptors.response.use(
      (res) => {
        Vue.$loading.hide();
        const result = res.data;
        const type = Object.prototype.toString.call(result);
        // 如果是文件流 直接返回
        if (type === "[object Blob]" || type === "[object ArrayBuffer]") {
          return result;
        } else {
          const { code, message } = result;
          console.log(code);
          // let timer = null;
          // //每次触发事件 都把定时器清掉重新计时
          // clearTimeout(timer)
          // timer = setTimeout(function(){
          //   //执行事件处理程序
          //   flag = true
          // }, 6000)
          // if (res.config.url !== '/user/api/user/web/version' && flag) {
          //   versionTood.isNewVersion();
          //   flag = false
          // }
          // versionTood.isNewVersion();
          if (code === 200) {
            return result;
          } else {
            if (code === 100985) {
              return Promise.reject(res);
            }
            // 上报错误信息
            if(window.actionlistner){
              window.actionlistner.reportError(code || 500, JSON.stringify(res));
            }
            console.log(code, 'codecodecodecodecode');
            if (code !== 30001 && code !== 30002) {
              if (code==100004){
                if (res.config.url=='/user/api/user/cheat/report'||res.config.url=='/user/api/user/cheat/packages'){
                  return
                }
              }
              errorToast(code || 500, message);
            }
            return Promise.reject(res);
          }
        }
      },
      (error) => {
        Vue.$loading.hide();
        if (error && error.response) {
          error.message = that.checkStatus(error.response.status);
        }
        const isTimeout = error.message.includes("timeout");
        const message = isTimeout
          ? "网络请求超时"
          : error.message || "连接到服务器失败";
        Toast(message);
        window.actionlistner.reportError(
          error.response.status,
          JSON.stringify(error)
        );
        return Promise.reject(error);
      }
    );
  }

  request(options) {
    const instance = axios.create();
    const baseOpt = this.getConfig();
    const params = Object.assign({}, baseOpt, this.getParams(options));
    // params.data = { ...params.data, userId: store.getters["user/userId"] };
    params.data = { ...params.data };
    this.setInterceptors(instance, options.loading);
    return instance(params);
  }
}

const http = new HttpRequest();
export default http;
