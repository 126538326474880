import http from "@/utils/request1";
let userInfo
try {
  if (window.actionlistner) {
    userInfo = window.actionlistner.getUserInfo();
    userInfo = JSON.parse(userInfo)
  }
}catch (e){
  console.log(e)
}

import store from "@/store";
import {cheatReport} from "@/api/cheat";
import cheat from "@/utils/CheatUtils";
// 根据UserId获取用户信息
export function getById() {
  if (userInfo) {
    return http.request({
      method: "post",
      url: `/user/api/user/getById?userId=${userInfo.id}`,
      loading: false,
    });
  } else {
    return http.request({
      method: "post",
      url: `/user/api/user/getById?userId=${store.getters["user/userId"]}`,
      loading: false,
    });
  }
  
}