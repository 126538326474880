const routes = [
  {
    path: "/refreshList",
    name: "refreshList",
    meta: {
      title: "自动刷新列表",
    },
    component: () => import("@/views/autoRefresh/refreshList.vue"),
  },
  {
    path: "/refreshForm",
    name: "refreshForm",
    meta: {
      title: "自动刷新表单",
    },
    component: () => import("@/views/autoRefresh/refreshForm.vue"),
  },
];
export default routes;