/*
 * @author:
 * @Description:
 * @Date: 2022-08-30 09:54:27
 * @LastEditTime: 2022-08-30 15:11:39
 * @FilePath: \sdk-h5\src\router\bargain.js
 */
const routes = [
  {
    path: "/browse/attentionDetails",
    name: "attentionDetails",
    meta: {
      title: "浏览任务",
    },
    component: () => import("@/views/browse/attentionDetails/index.vue"),
  },
  {
    path: "/browse/publish",
    name: "publish",
    meta: {
      title: "发布浏览任务",
      keepAlive: true,
    },
    component: () => import("@/views/browse/publish/index.vue"),
  },
  {
    path: "/browse/settingOpen",
    name: "settingOpen",
    meta: {
      title: "设置活动打开方式",
    },
    component: () => import("@/views/browse/settingOpen/index.vue"),
  },
  {
    path: "/browse/imgVoucher",
    name: "imgVoucher",
    meta: {
      title: "用户截图凭证",
    },
    component: () => import("@/views/browse/imgVoucher/index.vue"),
  },
  {
    path: "/browse/setTask",
    name: "setTask",
    meta: {
      title: "设置任务步骤",
    },
    component: () => import("@/views/browse/setTask/index.vue"),
  },
  {
    path: "/browse/collectInfo",
    name: "collectInfo",
    meta: {
      title: "收集用户信息",
    },
    component: () => import("@/views/browse/collectInfo/index.vue"),
  },
  {
    path: "/browse/attentionDetails",
    name: "attentionDetails",
    meta: {
      title: "收集用户信息",
    },
    component: () => import("@/views/browse/attentionDetails/index.vue"),
  },
  {
    path: "/browse/detail",
    name: "collectInfo",
    meta: {
      title: "任务详情",
    },
    component: () => import("@/views/browse/detail/index.vue"),
  },
];
export default routes;