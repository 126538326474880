const routes = [
  {
    path: "/trends",
    name: "trends",
    meta: {
      title: "动态",
      keepAlive: true,
    },
    component: () => import("@/views/trends/index.vue"),
  },
];

export default routes;
