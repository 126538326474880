<!--
 * @author: 
 * @Description: 
 * @Date: 2022-08-06 14:33:37
 * @LastEditTime: 2022-08-06 17:25:05
 * @FilePath: \LTQ-H5\src\components\base-list-one.vue
-->
<!--
 * @author: 
 * @Description: 
 * @Date: 2022-08-06 14:33:37
 * @LastEditTime: 2022-08-06 15:21:18
 * @FilePath: \LTQ-H5\src\components\base-list.vue
-->
<template>
  <div :class="$style.listBox">
    <div :class="$attrs['header-box']">
      <slot name="header"></slot>
    </div>
    <div :class="$attrs['content-box']">
      <slot name="content"></slot>
    </div>
    <div :class="$attrs['foot-box']">
      <slot name="footer"></slot>
    </div>
  </div>
</template>
<script>
export default {
  name: "base-list-one",
  data() {
    return {};
  },
  mounted() {
    console.log(this.$attrs, this.$style);
  },
};
</script>
<style lang="scss" module>
.listBox {
  width: 100vw;
  height: 100vh;
}
</style>
