/*
 * @author:
 * @Description:
 * @Date: 2022-08-30 09:54:27
 * @LastEditTime: 2022-09-07 10:26:09
 * @FilePath: \sdk-h5\src\router\daysTask.js
 */
const routes = [
  {
    path: "/daysTask/index",
    name: "daysTask",
    meta: {
      title: "任务详情",
    },
    component: () => import("@/views/daysTask/index.vue"),
  },
];
export default routes;
