<!--
 * @author: zhangzhaodi
 * @Description: 
 * @Date: 2022-08-24 11:19:07
 * @LastEditTime: 2022-10-08 14:07:39
 * @FilePath: \sdk-h5\src\components\loading\index.vue
-->
<template>
  <div>
    <van-popup v-model="show" :close-on-click-overlay="false">
      <van-loading size="24px" color="#ff4d00" vertical>{{ tips }}</van-loading>
    </van-popup>
  </div>
</template>
<script>
export default {
  name: "loading",
  props: {
    show: {
      default: false,
      type: Boolean,
    },
    tips: {
      default: "",
      type: String,
    },
    isDefault: {
      default: true,
      type: Boolean,
    },
  },
};
</script>
<style lang="scss" scoped>
::v-deep .van-popup {
  background: transparent;
}
::v-deep .van-overlay {
  background: rgba(0, 0, 0, 0.4);
}
</style>
