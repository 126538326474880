/*
 * @author:
 * @Description:
 * @Date: 2022-08-11 15:02:38
 * @LastEditTime: 2022-12-22 16:02:40
 * @FilePath: \sdk-h5\src\router\wallet.js
 */
const routes = [
  {
    path: "/wallet/mine",
    name: "mine",
    meta: {
      title: "我的",
      // keepAlive: true,
    },
    component: () => import("@/views/wallet/mine/index.vue"),
  },
  {
    path: "/wallet/lbbalance",
    name: "Lbbalance",
    meta: {
      title: "我的乐币",
    },
    component: () => import("@/views/wallet/lbbalance/index.vue"),
  },
  {
    path: "/wallet/cash",
    name: "Cash",
    meta: {
      title: "现金金额",
    },
    component: () => import("@/views/wallet/cash/index.vue"),
  },
  {
    path: "/wallet/proceed",
    name: "Proceed",
    meta: {
      title: "提现",
    },
    component: () => import("@/views/wallet/proceed/index.vue"),
  },
  {
    path: "/wallet/withdrawList",
    name: "WithdrawList",
    meta: {
      title: "提现记录",
    },
    component: () => import("@/views/wallet/withdrawList/index.vue"),
  },
  {
    path: "/wallet/details",
    name: "WithdrawDetails",
    meta: {
      title: "提现详情",
    },
    component: () => import("@/views/wallet/details/index.vue"),
  },
  {
    path: "/wallet/lecoinDrawal",
    name: "lecoinDrawal",
    meta: {
      title: "乐币提现",
    },
    // component: () => import("@/views/wallet/lecoin-drawal/index.vue"),
    component: () => import("@/views/wallet/lecoin-drawal/index.vue"),
  },
  {
    path: "/wallet/lbRecord",
    name: "lbRecord",
    meta: {
      title: "乐币提现记录",
    },
    component: () => import("@/views/wallet/lb-wd-record/index.vue"),
  },
  {
    path: "/wallet/income",
    name: "income",
    meta: {
      title: "收入明细",
    },
    component: () => import("@/views/wallet/income/index.vue"),
  },
  {
    path: "/wallet/welfare",
    name: "welfare",
    meta: {
      title: "福利中心",
    },
    component: () => import("@/views/wallet/welfare/index.vue"),
  },
];

export default routes;
