/*
 * @author:
 * @Description:
 * @Date: 2022-08-11 15:02:38
 * @LastEditTime: 2022-08-11 15:50:20
 * @FilePath: 
 */
const routes = [
    {
      path: "/welfare",
      name: "welfare",
      meta: {
        title: "福利中心",
      },
      component: () => import("@/views/welfare/home/index.vue"),
    },
    {
      path: "/welfareCenter",
      name: "welfareCenter",
      meta: {
        title: "福利中心",
      },
      component: () => import("@/views/welfareCenter/index.vue"),
    },
    {
      path: "/welfareCenter/rule",
      name: "rule",
      meta: {
        title: "活动规则",
      },
      component: () => import("@/views/welfareCenter/rule.vue"),
    },
  ];
  
  export default routes;
  