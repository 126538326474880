/*
 * @author: zhanggenyuan
 * @Description:
 * @Date: 2022-06-01 10:16:38
 * @LastEditTime: 2023-02-07 18:38:47
 * @FilePath: \sdk-h5\src\store\modules\user.js
 */
export default {
  namespaced: true,
  state: {
    userInfo: {},
    visited: [],
    flag: false,
    statCode: '',
    appletExtendUserId:'',
    // email: ''
  },
  getters: {
    token: (state) => state.userInfo.token || "lt115301285838590894091",
    // userId: (state) => state.userInfo.id || "1562742700867325953",
    // userId: (state) => state.userInfo.id || "1592005402831765505", // 测试
    // userId: (state) => "1592038299901083650", // 测试
    userId: (state) => {
      if (process.env.VUE_APP_FLAG == "test") {
        //如果有需要修改userid请修改此模块的userid
        return state.userInfo.id || "1688723916436758530"
      } else {
        //不要修改这里的userid不要修改这里的userid不要修改这里的userid不要修改这里的userid不要修改这里的userid
        return state.userInfo.id || "1613838466572566529"
      }
    }, // 测试

    visited: (state) => state.visited || [],
    flag: (state) => state.flag || false,
    statCode: (state) => '',
    // email: (state) => state.email || "",
    // userId: (state) => state.userInfo.userId || "1546767362543804418",
    // 1562408218081869826

    appletExtendUserId:(state) => state.appletExtendUserId || ""
  },
  mutations: {
    setUserInfo(state, payload) {
      state.userInfo = payload;
    },
    setVisited(state, payload) {
      state.visited.push(payload);
    },
    setFlag(state, payload) {
      state.flag = payload;
    },
    statCode(state, payload) {
      state.statCode = payload;
    },
    setAppletExtendUserId(state, payload) {
      state.appletExtendUserId = payload;
    },
    // setEmail(state, payload) {
    //   state.email = payload
    // },
  },
  actions: {},
};

// {
// 	"token": "xxxxxx",
// 	"dyAuthorStatus": "1",
// 	"dyBindCount": "1",
// 	"dyHeadImage": "https://p3.douyinpic.com/aweme/100x100/aweme-avatar/tos-cn-i-0813_c76ea909e1344e25befaff17c8193599.jpeg?from\u003d4010531038",
// 	"dyNickName": "老丁头剪辑",
// 	"dyNo": "56269534579",
// 	"headImage": "https://thirdwx.qlogo.cn/mmopen/vi_32/Q0j4TwGTfTKricP7yWQ3Fq2YoPnV7PGdHGiaFiagzKA4qGml65Qo8Ngxc4WOREIKFhlEljFpKzchrnic5p7CNUBKfw/132",
// 	"logoutMsg": "",
// 	"nickName": "这里是微信昵称",
// 	"openId": "2db53495-6776-47b5-9e8f-94162ce8eff8",
// 	"surMoney": "0.0",
// 	"userCode": "44885801",
// 	"userId": "1513941353753518081",
// 	"userStatus": "1"
// }
