/*
 * @author: wangchunjie
 * @Description:
 * @Date: 2022-08-11 13:50:43
 * @LastEditTime: 2022-09-14 09:15:06
 * @FilePath: \sdk-h5\src\router\follow.js
 */
const routes = [
  {
    path: "/",
    redirect: "/follow/missionHall",
  },
  {
    path: "/demo",
    name: "demo",
    meta: {
      title: "组件",
    },
    component: () => import("@/views/demo.vue"),
  },
  {
    path: "/home",
    name: "home",
    meta: {
      title: "组件",
    },
    component: () => import("@/components/footerTab.vue"),
  },
  {
    path: "/follow/missionHall",
    name: "missionHall",
    meta: {
      title: "任务大厅",
      keepAlive: true,
    },
    component: () => import("@/views/follow/missionHall/index.vue"),
  },
  {
    path: "/follow/homeHall",
    name: "homeHall",
    meta: {
      title: "任务大厅",
      keepAlive: true,
    },
    component: () => import("@/views/follow/homeHall/index.vue"),
  },
  {
    path: "/follow/details",
    name: "details",
    meta: {
      title: "任务详情",
    },
    component: () => import("@/views/follow/details/index.vue"),
  },
  {
    path: "/follow/billDetails",
    name: "billDetails",
    meta: {
      title: "账单明细",
    },
    component: () => import("@/views/follow/billDetails/index.vue"),
  },
  {
    path: "/follow/attentionDetails",
    name: "attentionDetails",
    meta: {
      title: "任务详情", // 更具tab展示标头
    },
    component: () => import("@/views/follow/attentionDetails/index.vue"),
  },
  {
    path: "/follow/registered",
    name: "registered",
    meta: {
      title: "我报名的",
    },
    component: () => import("@/views/follow/registered/index.vue"),
  },
  {
    path: "/follow/iPosted",
    name: "iPosted",
    meta: {
      title: "我发布的",
    },
    component: () => import("@/views/follow/iPosted/index.vue"),
  },
  {
    path: "/follow/publishFocusTasks2",
    name: "publishFocusTasks",
    meta: {
      title: "发布关注任务",
    },
    component: () => import("@/views/follow/publishFocusTasks/index.vue"),
  },
  {
    path: "/follow/publishFocusTasks",
    name: "publishFocusTasks2",
    meta: {
      title: "发布关注任务",
      keepAlive: true,
    },
    component: () => import("@/views/follow/publishFocusTasks2/index.vue"),
  },
  {
    path: "/follow/publishTask",
    name: "publishTask",
    meta: {
      title: "发布任务",
      keepAlive: true,
    },
    component: () => import("@/views/follow/publishTask/index.vue"),
  },
  {
    path: "/follow/rules",
    name: "rules",
    meta: {
      title: "任务规则",
    },
    component: () => import("@/views/follow/rules/index.vue"),
  },
  {
    path: "/follow/qualification/list",
    name: "rulequalificationsList",
    meta: {
      title: "资质认证",
    },
    component: () => import("@/views/follow/qualification/list/index.vue"),
  },
  {
    path: "/follow/qualification/form",
    name: "rulequalificationsForm",
    meta: {
      title: "资质认证",
    },
    component: () => import("@/views/follow/qualification/form/index.vue"),
  },
  {
    path: "/follow/qualification/result",
    name: "qualificationResult",
    meta: {
      title: "认证结果",
    },
    component: () => import("@/views/follow/qualification/result/index.vue"),
  },
  {
    path: "/reasonForFailure",
    name: "reasonForFailure",
    meta: {
      title: "审核不通过原因",
    },
    component: () => import("@/views/follow/iPosted/reasonForFailure.vue"),
  },
];

export default routes;
