/**
 *   request2.js  用于小程序推广计划  appletExtend.js
 * */
"use strict";
import axios from "axios";
import {Toast} from "vant";

import $loading from "@/components/loading/index.js";
import Vue from "vue";

Vue.use($loading);

class HttpRequest {
    // #baseUrl
    constructor() {

        this.baseUrl = this.getBaseUrl();
        this.withCredentials = false;
        this.timeout = 10000;
    }

    getBaseUrl() {
        return process.env.VUE_APP_BASEURL;
    }

    getConfig() {
        const config = {
            baseURL: this.baseUrl,
            timeout: this.timeout,
            withCredentials: this.withCredentials,
            headers: {
                "Content-Type": "application/json;charset=UTF-8",
            },
        };
        return config;
    }

    getParams(payload) {
        const {method, data} = payload;
        if (["post", "put", "patch", "delete"].indexOf(method) >= 0) {
            payload.data = data;
        } else {
            payload.params = data;
            delete payload.data;
        }
        return payload;
    }

    // 拦截处理
    setInterceptors(instance, loading = false) {
        const that = this;
        // 请求拦截
        instance.interceptors.request.use(
            (config) => {
                if (!navigator.onLine) {
                    Toast("请检查您的网络是否正常");
                    return Promise.reject("请检查您的网络是否正常");
                }

                config.headers["x-login-token"] = localStorage.getItem('appletExtendUserId');

                return config;
            },
            (error) => {
                Vue.$loading.hide();
                return Promise.reject(error);
            }
        );

        //响应拦截
        instance.interceptors.response.use(
            (res) => {
                Vue.$loading.hide();
                const result = res.data;
                return result;
            },

        );
    }

    request(options) {
        const instance = axios.create();
        const baseOpt = this.getConfig();
        const params = Object.assign({}, baseOpt, this.getParams(options));
        params.data = {...params.data};
        this.setInterceptors(instance, options.loading);
        return instance(params);
    }
}

const http = new HttpRequest();
export default http;
