/*
 * @author: zhangzhaodi
 * @Description:
 * @Date: 2022-09-19 11:31:10
 * @LastEditTime: 2022-10-09 14:04:45
 * @FilePath: \sdk-h5\src\router\pureDrainage.js
 */
const routes = [
  {
    path: "/pureDrainage/publish",
    name: "drainagePublish",
    meta: {
      title: "发布纯引流",
    },
    component: () => import("@/views/pureDrainage/publish/index.vue"),
  },
  {
    path: "/pureDrainage/attentionDetails",
    name: "pureDrainageAttentionDetails",
    meta: {
      title: "纯引流任务",
    },
    component: () => import("@/views/pureDrainage/attentionDetails/index.vue"),
  },
];
export default routes;
