/*
 * @author:
 * @Description:
 * @Date: 2022-08-06 15:50:47
 * @LastEditTime: 2022-08-06 16:27:54
 * @FilePath: \LTQ-H5\src\plugins\components\index.js
 */
import componentAll from "@/components/index";
export default {
  install: (Vue) => {
    Object.keys(componentAll).forEach((v) => {
      Vue.component(componentAll[v].name, componentAll[v]);
    });
  },
};
