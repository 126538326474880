/*
 * @author:
 * @Description:
 * @Date: 2022-08-30 09:54:27
 * @LastEditTime: 2022-08-30 15:11:39
 * @FilePath: \sdk-h5\src\router\bargain.js
 */
const routes = [
  {
    path: "/videoList",
    name: "videoList",
    meta: {
      title: "视频列表",
    },
    component: () => import("@/views/video/index.vue"),
  },
  {
    path: "/video",
    name: "video",
    meta: {
      title: "视频列表",
    },
    component: () => import("@/views/video/video.vue"),
  },
];
export default routes;
