/*
 * @author:
 * @Description:
 * @Date: 2022-08-30 09:54:27
 * @LastEditTime: 2022-12-24 14:21:35
 * @FilePath: \sdk-h5\src\router\multiStage.js
 */
const routes = [
  {
    path: "/multiStage/index",
    name: "multiStage",
    meta: {
      title: "多阶段任务",
    },
    component: () => import("@/views/multiStage/index.vue"),
  },
];
export default routes;
