/*
 * @author:
 * @Description:
 * @Date: 2022-08-30 09:54:27
 * @LastEditTime: 2022-12-30 14:42:53
 * @FilePath: \sdk-h5\src\router\cpl.js
 */
const routes = [
  {
    path: "/cpl/strategy",
    name: "strategy",
    meta: {
      title: "试玩攻略",
    },
    component: () => import("@/views/cpl/strategy/index.vue"),
  },
  {
    path: "/cpl/details",
    name: "details",
    meta: {
      title: "试玩攻略",
    },
    component: () => import("@/views/cpl/details/index.vue"),
  },
  {
    path: "/cpl/mygame",
    name: "myGame",
    meta: {
      title: "我的游戏",
    },
    component: () => import("@/views/cpl/myGame/myGame.vue"),
  },
  {
    path: "/cpl/hall",
    name: "hall",
    meta: {
      title: "游戏大厅",
      keepAlive: true
    },
    component: () => import("@/views/cpl/hall/index.vue"),
  },
  {
    path: "/cpl/rule",
    name: "rule",
    meta: {
      title: "游戏攻略",
      keepAlive: true
    },
    component: () => import("@/views/cpl/hall/rule.vue"),
  },
  {
    path: "/cpl/search",
    name: "search",
    meta: {
      title: "游戏搜索"
    },
    component: () => import("@/views/cpl/hall/search.vue"),
  },
  {
    path: "/cpl/activity",
    name: "activity",
    meta: {
      title: "活动详情"
    },
    component: () => import("@/views/cpl/activity/index.vue"),
  },
  {
    path: "/cpl/activityRule",
    name: "activityRule",
    meta: {
      title: "活动规则"
    },
    component: () => import("@/views/cpl/activity/rule.vue"),
  },
];
export default routes;
