/*
 * @author: zhanggenyuan
 * @Description:
 * @Date: 2022-05-31 19:57:25
 * @LastEditTime: 2022-12-26 09:20:19
 * @FilePath: \sdk-h5\src\router\index.js
 */
import Vue from "vue";
import VueRouter from "vue-router";
import { getById } from "@/api/information.js"
import follow from "@/router/follow";
import likeTask from "@/router/likeTask.js";
import secondReview from "@/router/secondReview.js";
import bargain from "@/router/bargain.js";
import applet from "@/router/applet.js";
import vote from "@/router/vote.js";
import browse from "@/router/browse.js";
import downloadTasks from "@/router/downloadTasks.js";
import attestationBindingCard from "@/router/attestationBindingCard.js";
import register from "@/router/register.js";
import taskStep from "@/router/taskStep.js";
import daysTask from "@/router/daysTask.js";
import wallet from "@/router/wallet.js";
import welfare from "@/router/welfare.js";
import pureDrainage from "@/router/pureDrainage.js";
import addAccount from "@/router/addAccount.js";
import multiStage from "@/router/multiStage.js";
import manyDays from "@/router/manyDays.js";
import course from "@/router/course.js";
import img from "@/router/img.js";
import cpl from "@/router/cpl.js";
import myVoucher from '@/router/myVoucher.js'
import recharge from '@/router/recharge.js'
import trends from '@/router/trends.js'
import versionTood from '@/utils/versionUpdate';
import video from "@/router/video.js"
import topContent from '@/router/topContent.js'
import autoRefresh from '@/router/autoRefresh.js'
import extend from '@/router/appletExtend.js'


Vue.use(VueRouter);

const router = new VueRouter({
  routes: [
      ...extend,
    ...follow,
    ...likeTask,
    ...secondReview,
    ...bargain,
    ...applet,
    ...vote,
    ...browse,
    ...downloadTasks,
    ...attestationBindingCard,
    ...register,
    ...taskStep,
    ...daysTask,
    ...wallet,
    ...welfare,
    ...pureDrainage,
    ...addAccount,
    ...multiStage,
    ...manyDays,
    ...course,
    ...img,
    ...cpl,
    ...myVoucher,
    ...recharge,
    ...trends,
    ...video,
    ...topContent,
    ...autoRefresh
  ],
  mode: "history",
});
import store from "@/store";
let flag = false
router.beforeEach((to, from, next) => {
  console.log(from, 'fromfromfromfromfromfrom');
  console.log(store.getters["user/userId"], 'store.getters["user/userId"]');
  let userId = store.getters["user/userId"]
  console.log(userId, 'userIduserIduserIduserIduserId');
  let data = {
    userId: store.getters["user/userId"]
  }
  if (to.fullPath === '/follow/missionHall' && from.fullPath === '/') {
    if (flag) {
      next()
    } else {
      let userId = store.getters["user/userId"]
      getById(userId)
      .then((res) => {
        let functionTypes = res.result.channelPermission.functionTypes
        flag = true
        console.log(functionTypes, 'functionTypesfunctionTypesfunctionTypes');
        console.log(functionTypes.indexOf(5) !== -1, 'functionTypes.indexOf(5) !== -1');
        if (functionTypes.indexOf(5) !== -1) {
          next({
            path: '/topContent',
            replace: true
          })
        } else {
          next()
        }
      })
    }
  } else {
    next()
  }
    // console.log(to, 'totototo');
    //判断当前代码版本是否与服务器中代码版本一致，如不一致则刷新页面获取最新
    // versionTood.isNewVersion();
    // if (data) {

    // }
})
export default router;