<!--
 * @author: 
 * @Description: 
 * @Date: 2022-08-06 17:25:45
 * @LastEditTime: 2022-08-06 17:49:32
 * @FilePath: \LTQ-H5\src\components\base-list-two.vue
-->
<template>
  <div :class="$style.listBox">
    <div :class="$style['header-box']">
      <van-nav-bar :title="topTitle" left-arrow @click-left="onClickLeft" />
    </div>
    <div :class="contentBox">
      <slot name="content"></slot>
    </div>
    <div :class="$attrs['foot-box']" v-if="isDisFooter">
      <slot name="footer"></slot>
    </div>
  </div>
</template>
<script>
export default {
  name: "base-list-two",
  props: {
    // 顶部tab文案
    topTitle: {
      type: String,
      default: "",
    },
    // 左侧的点击事件
    leftClickFun: {
      type: Function,
    },
    // 是否展示底部
    isDisFooter: {
      type: Boolean,
      default: false,
    },
    // 中间css
    contentBox: {
      type: Object,
      default: () => {
        return {
          width: "100%",
          height: "calc(100%" + "-" + "98px)",
          overflow: "auto",
        };
      },
    },
  },
  data() {
    return {};
  },
  mounted() {
    console.log(this.contentBox);
  },
  methods: {
    /**
     * @desc 左侧点击事件
     * @date 2022-08-06
     * @returns {any}
     */
    onClickLeft() {
      if (this.leftClickFun) {
        this.leftClickFun();
        return;
      }
      this.$emit("onClickLeft");
    },
  },
};
</script>
<style lang="scss" module>
.listBox {
  width: 100vw;
  height: 100vh;
  .header-box {
    position: sticky;
    top: 0;
    width: 100%;
    height: 98px;
    background: red;
  }
}
</style>
