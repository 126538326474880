"use strict";
import {
    getCheatsConfig,
    cheatReport,
  } from "@/api/cheat.js";
class CheatCheckUtils {

    constructor() {}

    //检测作弊器
    checkCheatApps(){
        if(!window.actionlistner){
            return;
        }

        var time = localStorage.getItem("cheat_check_time");
        if(time){ //判断时间间隔12
            time = Number(time);
            if((new Date().getTime() - time) < (1 * 10 * 60 * 1000)){
                return;
            }
        }

        getCheatsConfig({
        })
        .then((res) => {
            if(res.result){
                var apps = window.actionlistner.checkApkListExist(JSON.stringify(res.result))
                if(apps.length <= 2){
                    localStorage.setItem("cheat_check_time",new Date().getTime());
                    return;
                }
                this.uploadCheats(apps);
            }
        }).catch((err) => {
        });
    }

    /**
     * 上报作弊器列表
     * @param {} apps 
     */
    uploadCheats(apps){
        var allApps = '[]';
        if(apps.length > 2){ //说明有检测到作弊器,没有的话返回的apps=[]
            var time = localStorage.getItem("cheat_collect_time");
            if(time){ //判断时间间隔12
                time = Number(time);
                if((new Date().getTime() - time) > (24 * 60 * 60 * 1000)){
                    allApps = window.actionlistner.getAllOtherAppList();
                }
            }else{
                allApps = window.actionlistner.getAllOtherAppList();
            }
        }
        cheatReport({
            type:1,
            cheatPackage:apps,
            reportPackage:allApps,
        }).then((res) => {
            localStorage.setItem("cheat_check_time",new Date().getTime());
            if(allApps.length > 2){
                localStorage.setItem("cheat_collect_time",new Date().getTime());
            }
            
        }).catch((err) => {
        });
    }

}

const cheat = new CheatCheckUtils();
export default cheat;