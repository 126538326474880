
const routes = [
  // {
  //   path: "/",
  //   redirect: "/topContent",
  // },
  {
    path: "/topContent",
    name: "topContent",
    meta: {
      title: "热门任务",
      keepAlive: true
    },
    component: () => import("@/views/topContent/index.vue"),
  },
  {
    path: "/topContent/refreshToTop",
    name: "refreshToTop",
    meta: {
      title: "置顶购买页面",
    },
    component: () => import("@/views/topContent/refreshToTop.vue"),
  },
  {
    path: "/topContent/refresh",
    name: "refresh",
    meta: {
      title: "刷新购买页面",
    },
    component: () => import("@/views/topContent/refresh.vue"),
  },
  {
    path: "/topContent/takeNote",
    name: "takeNote",
    meta: {
      title: "使用记录",
    },
    component: () => import("@/views/topContent/takeNote.vue"),
  },
  {
    path: "/iPosted/rule",
    name: "rule",
    meta: {
      title: "置顶规则",
    },
    component: () => import("@/views/follow/iPosted/rule.vue"),
  }
];
export default routes;
