/*
 * @author:
 * @Description:
 * @Date: 2022-08-30 09:54:27
 * @LastEditTime: 2022-08-30 15:11:39
 * @FilePath: \sdk-h5\src\router\bargain.js
 */
const routes = [
  {
    path: "/vote/attentionDetails",
    name: "attentionDetails",
    meta: {
      title: "简单投票",
    },
    component: () => import("@/views/vote/attentionDetails/index.vue"),
  },
  {
    path: "/vote/publish",
    name: "publishs",
    meta: {
      title: "发布任务",
      keepAlive: true,
    },
    component: () => import("@/views/vote/publish/index.vue"),
  },
  {
    path: "/vote/setTask",
    name: "setTask",
    meta: {
      title: "设置任务步骤",
      keepAlive: true,
    },
    component: () => import("@/views/vote/setTask/index.vue"),
  },
  {
    path: "/vote/settingOpen",
    name: "settingOpen",
    meta: {
      title: "设置任务步骤",
      keepAlive: true,
    },
    component: () => import("@/views/vote/settingOpen/index.vue"),
  },
  {
    path: "/vote/imgVoucher",
    name: "imgVoucher",
    meta: {
      title: "用户截图凭证",
      keepAlive: true,
    },
    component: () => import("@/views/vote/imgVoucher/index.vue"),
  },
];
export default routes;
