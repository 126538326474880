/*
 * @author:
 * @Description:
 * @Date: 2022-08-30 09:54:27
 * @LastEditTime: 2022-12-26 09:23:55
 * @FilePath: \sdk-h5\src\router\manyDays.js
 */
const routes = [
  {
    path: "/manyDays/index",
    name: "manyDays",
    meta: {
      title: "多天任务",
    },
    component: () => import("@/views/manyDays/index.vue"),
  },
];
export default routes;
