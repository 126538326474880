/*
 * @author: zhanggenyuan
 * @Description:
 * @Date: 2022-06-08 17:36:35
 * @LastEditTime: 2022-06-08 17:37:08
 * @FilePath: \h5\src\store\modules\drainage.js
 */
export default {
  namespaced: true,
  state: {
    checked: false,
    platformId: 0,
    sortCode: "prize",
  },
  mutations: {
    set_checked(state, payload) {
      state.checked = payload;
    },
    set_platformId(state, id) {
      state.platformId = id;
    },
    set_sortCode(state, code) {
      state.sortCode = code;
    },
  },
};
