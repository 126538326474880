/*
 * @author:
 * @Description:
 * @Date: 2022-08-30 09:54:27
 * @LastEditTime: 2022-10-14 14:53:02
 * @FilePath: \sdk-h5\src\router\course.js
 */
const routes = [
  {
    path: "/course/index",
    name: "course",
    meta: {
      title: "发布教程",
    },
    component: () => import("@/views/course/index.vue"),
  },
  {
    path: "/course/publishNotice",
    name: "publishNotice",
    meta: {
      title: "发布",
    },
    component: () => import("@/views/course/publishNotice.vue"),
  },
  {
    path: "/course/toExamine",
    name: "toExamine",
    meta: {
      title: "发布",
    },
    component: () => import("@/views/course/toExamine.vue"),
  },
  {
    path: "/course/howToExamine",
    name: "howToExamine",
    meta: {
      title: "发布",
    },
    component: () => import("@/views/course/howToExamine.vue"),
  },
  {
    path: "/course/howPublish",
    name: "howPublish",
    meta: {
      title: "发布",
    },
    component: () => import("@/views/course/howPublish.vue"),
  },
  {
    path: "/course/howPublishMe",
    name: "howPublishMe",
    meta: {
      title: "发布",
    },
    component: () => import("@/views/course/howPublishMe.vue"),
  },
];
export default routes;
