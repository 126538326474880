/*
 * @author: zhangzhaodi
 * @Description:
 * @Date: 2022-08-31 13:52:12
 * @LastEditTime: 2022-08-31 13:58:29
 * @FilePath: \sdk-h5\src\router\drainage.js
 */
const routes = [
  {
    path: "/image",
    name: "image",
    meta: {
      title: "图片",
    },
    component: () => import("@/views/image/index.vue"),
  },
];
export default routes;
