/*
 * @author:
 * @Description:
 * @Date: 2022-08-30 09:54:27
 * @LastEditTime: 2022-09-17 15:20:02
 * @FilePath: \sdk-h5\src\router\taskStep.js
 */
const routes = [
  {
    path: "/taskStep/settingOpen",
    name: "settingOpen",
    meta: {
      title: "设置活动打开方式",
    },
    component: () => import("@/views/taskStep/settingOpen/index.vue"),
  },
  {
    path: "/taskStep/imgVoucher",
    name: "imgVoucher",
    meta: {
      title: "用户截图凭证",
    },
    component: () => import("@/views/taskStep/imgVoucher/index.vue"),
  },
  {
    path: "/taskStep/setTask",
    name: "setTask",
    meta: {
      title: "设置任务步骤",
    },
    component: () => import("@/views/taskStep/setTask/index.vue"),
  },
  {
    path: "/taskStep/collectInfo",
    name: "collectInfo",
    meta: {
      title: "收集用户信息",
    },
    component: () => import("@/views/taskStep/collectInfo/index.vue"),
  },
  {
    path: "/taskStep/uploadAvatar",
    name: "uploadAvatar",
    meta: {
      title: "上传头像",
    },
    component: () => import("@/views/taskStep/uploadAvatar/index.vue"),
  },
];
export default routes;
