/*
 * @author: wangchunjie
 * @Description:
 * @Date: 2022-08-11 13:50:43
 * @LastEditTime: 2022-08-24 09:58:43
 * @FilePath: \sdk-h5\src\router\follow.js
 */
const routes = [
  {
    path: "/secondReview/attentionDetails",
    name: "secondReviewAttentionDetails",
    meta: {
      title: "任务详情-秒审",
    },
    component: () => import("@/views/secondReview/attentionDetails/index.vue"),
  }
];

export default routes;
