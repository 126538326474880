/*
 * @author:
 * @Description:
 * @Date: 2022-08-30 09:54:27
 * @LastEditTime: 2022-08-30 15:11:39
 * @FilePath: \sdk-h5\src\router\bargain.js
 */
const routes = [
  {
    path: "/downloadTasks/attentionDetails",
    name: "attentionDetails",
    meta: {
      title: "下载任务",
    },
    component: () => import("@/views/downloadTasks/attentionDetails/index.vue"),
  },
  {
    path: "/downloadTasks/answerDetail",
    name: "answerDetail",
    meta: {
      title: "答题试玩",
    },
    component: () => import("@/views/answer/detail/index.vue"),
  },
  {
    path: "/downloadTasks/publish",
    name: "publish",
    meta: {
      title: "发布任务",
      keepAlive: true,
    },
    component: () => import("@/views/downloadTasks/publish/index.vue"),
  },
  {
    path: "/downloadTasks/setTask",
    name: "setTask",
    meta: {
      title: "设置任务步骤",
    },
    component: () => import("@/views/downloadTasks/setTask/index.vue"),
  },
  {
    path: "/downloadTasks/settingOpen",
    name: "settingOpen",
    meta: {
      title: "设置任务步骤",
    },
    component: () => import("@/views/downloadTasks/settingOpen/index.vue"),
  },
  {
    path: "/downloadTasks/imgVoucher",
    name: "imgVoucher",
    meta: {
      title: "用户截图凭证",
    },
    component: () => import("@/views/downloadTasks/imgVoucher/index.vue"),
  },
];
export default routes;