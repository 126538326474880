<!--
 * @author: zhanggenyuan
 * @Description: 
 * @Date: 2022-06-07 13:54:06
 * @LastEditTime: 2022-08-06 15:55:05
 * @FilePath: \LTQ-H5\src\components\circle.vue
-->
<template>
  <div class="circle">
    <div class="round" :class="[newline ? 'newline' : '']"></div>
    <div class="slot">
      <slot />
    </div>
  </div>
</template>

<script>
export default {
  name: "base-circle",
  data() {
    return {};
  },
  props: {
    newline: {
      type: Boolean,
      default: false,
    },
  },
};
</script>

<style lang="scss" scoped>
.circle {
  display: flex;
  align-items: center;
  padding: 10px 0;
  .round {
    width: 10px;
    height: 10px;
    border: 6px solid #ff4d00;
    border-radius: 50%;
    margin-right: 10px;
  }
  .newline {
    margin-top: -30px;
  }
  .slot {
    font-size: 26px;
    font-family: Source Han Sans CN;
    font-weight: bold;
    color: #333333;
  }
}
</style>
