<!--
 * @author: zhanggenyuan
 * @Description:
 * @Date: 2022-05-31 19:57:25
 * @LastEditTime: 2023-01-16 18:56:58
 * @FilePath: \sdk-h5\src\App.vue
-->
<template>
	<div>
		<router-view v-if="!$route.meta.keepAlive"></router-view>
		<keep-alive>
			<router-view v-if="$route.meta.keepAlive"></router-view>
		</keep-alive>
	</div>
</template>
<script>
import {mapMutations} from "vuex";
import store from "@/store";
import {cheatReport} from "@/api/cheat";
import cheat from "@/utils/CheatUtils";
import {userSave} from "@/api/appletExtend";
import {toBase64} from "@/utils/base64";
export default {
	created() {

    const urlParams = new URLSearchParams(window.location.href.split('?')[1]);
    const params = {};
    for (let param of urlParams.entries()) {
      params[param[0]] = param[1];
    }
    if (window.location.pathname==='/extend'){
      let data = {
        appId: params.appId,
        appSecret: params.appSecret,
        thirdUserId: params.thirdUserId,
        userName: params.userName,
        userAvatar: params.userAvatar,
        sysApiVersion: params.sysApiVersion,
        deviceBrand: params.deviceBrand,
        deviceModel: params.deviceModel,
        os: params.os,
        developModeFlag: params.developModeFlag,
        simState:params.simState,
        rootFlag: params.rootFlag,
        simulatorFlag: params.simulatorFlag,
        oaId: params.oaId,
        imei: params.imei,
        androidId: params.androidId
      }

      userSave({
        appId: params.appId,
        appSecret: params.appSecret,
        params: toBase64(JSON.stringify(data)),
        thirdUserId: params.thirdUserId,
        userType: 0,
      }).then((res) => {

        let userInfo = {
          id: res.result.id
        }
        this.setUserInfo(userInfo);
        localStorage.setItem('appletExtendUserId',res.result.id)
      }).catch((err) => {
      });

      return
    }

		let token = this.$route.query.token
		console.log(token, 'tokentokentokentoken');
		if (token) {
			let userInfo = {
				id: token
			}
			this.setUserInfo(userInfo);
			this.setFlag(true)
		} else {
			this.setFlag(false)
		}
		if (window.actionlistner) {

      try {
        const userInfo = window.actionlistner.getUserInfo();

        if (userInfo && !store.getters["user/flag"] && !token) {
          this.setUserInfo(JSON.parse(userInfo));
        }
        const getBarrierApps = window.actionlistner.getBarrierApps()
        const newGetBarrierApps = JSON.parse(getBarrierApps)
        setTimeout(function () {
          console.log(store.getters["user/flag"],8888888888)
          cheatReport({type:2, reportPackage:newGetBarrierApps.length!=0?getBarrierApps:null,}).then((res) => {}).catch((err) => {});
          cheat.checkCheatApps();
        },1000)
      }catch (e){
        console.log(e)
      }

		}
	},
  methods: {
		...mapMutations("user", ["setUserInfo", "setFlag"]),
	},
};
</script>
<style lang="scss">
.app-box {
	width: 100%;
	height: 100vh;
	background: rgb(242, 242, 242);
}
</style>
