import http from "@/utils/request2";

export function userSave(data) {
    return http.request({
        url: `/api/gateway/user/save`,
        method: "post",
        data: data,
        loading: false,
    });
}

/***** 推广计划 *****/

export function getLinlk(data) {
    return http.request({
        url: `/order/api/promotion/getLinlk`,
        method: "post",
        data: data,
        loading: false,
    });
}

export function getAgencyUserBindRecord(data) {
    return http.request({
        url: `/order/api/promotion/getAgencyUserBindRecord`,
        method: "post",
        data: data,
        loading: false,
    });
}

export function promotionIncomeDetail(data) {
    return http.request({
        url: `/order/api/promotion/incomeDetail`,
        method: "post",
        data: data,
        loading: false,
    });
}

export function promotionOrderList(data) {
    return http.request({
        url: `/order/api/promotion/orderList?size=${data.size}&current=${data.current}`,
        method: "post",
        data: data,
        loading: false,
    });
}


/*******  ******/
export function totalIncome(data) {
    return http.request({
        url: `/order/api/source/order/totalIncome`,
        method: "post",
        data: data,
        loading: false,
    });
}

export function incomeDetail(data) {
    return http.request({
        url: `/order/api/source/order/incomeDetail`,
        method: "post",
        data: data,
        loading: false,
    });
}

export function orderList(data) {
    return http.request({
        url: `/order/api/source/order/orderList?size=${data.size}&current=${data.current}`,
        method: "post",
        data: data,
        loading: false,
    });
}

/******  *******/
export function providerList(data) {
    return http.request({
        url: `/order/api/promotion/provider/list`,
        method: "post",
        data: data,
        loading: false,
    });
}

export function sourceList(data) {
    return http.request({
        url: `/order/api/source/list?size=${data.size}&current=${data.current}`,
        method: "post",
        data: data,
        loading: false,
    });
}

export function bookChapter(data) {
    return http.request({
        url: `/order/api/source/getNovelChapter`,
        method: "post",
        data: data,
        loading: false,
    });
}

export function sourceDetail(data) {
    return http.request({
        url: `/order/api/source/detail`,
        method: "post",
        data: data,
        loading: false,
    });
}

export function createPromote(data) {
    return http.request({
        url: `/order/api/source/createPromote`,
        method: "post",
        data: data,
        loading: false,
    });
}


export function uploadTrace(data) {
    return http.request({
        url: `/order/api/trace/uploadTrace`,
        method: "post",
        data: data,
        loading: false,
    });
}

export function wxGetCode(data) {
    return http.request({
        url: `/order/api/source/getCode`,
        method: "post",
        data: data,
        loading: false,
    });
}


export function getSourceRankLst(data) {
    return http.request({
        url: `/order/api/source/getSourceRankLst?size=${data.size}&current=${data.current}`,
        method: "post",
        data: data,
        loading: false,
    });
}

export function detailList(data) {
    return http.request({
        url: `/order/api/source/detailList?size=${data.size}&current=${data.current}`,
        method: "post",
        data: data,
        loading: false,
    });
}