import http from "@/utils/request";

//无障碍模式，作弊上报
export function cheatReport(data) {
    return http.request({
        method: "post",
        url: "/user/api/user/cheat/report",
        data,
        loading: false,
    });
}
//获取作弊器app配置
export function getCheatsConfig(data){
    return http.request({
      method: "post",
      url: "/user/api/user/cheat/packages",
      data,
        loading: false,
    });
  }