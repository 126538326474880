/*
 * @author:
 * @Description:
 * @Date: 2022-08-30 09:54:27
 * @LastEditTime: 2022-09-13 16:06:35
 * @FilePath: \sdk-h5\src\router\applet.js
 */
const routes = [
  {
    path: "/applet/attentionDetails",
    name: "attentionDetails",
    meta: {
      title: "程序授权",
    },
    component: () => import("@/views/applet/attentionDetails/index.vue"),
  },
  {
    path: "/applet/publish",
    name: "publish",
    meta: {
      title: "发布任务",
      keepAlive: true,
    },
    component: () => import("@/views/applet/publish/index.vue"),
  },
  {
    path: "/applet/setTask",
    name: "appletsetTask",
    meta: {
      title: "设置任务步骤",
    },
    component: () => import("@/views/applet/setTask/index.vue"),
  },
  {
    path: "/applet/settingOpen",
    name: "appletsettingOpen",
    meta: {
      title: "设置任务步骤",
    },
    component: () => import("@/views/applet/settingOpen/index.vue"),
  },
  {
    path: "/applet/imgVoucher",
    name: "appletimgVoucher",
    meta: {
      title: "用户截图凭证",
    },
    component: () => import("@/views/applet/imgVoucher/index.vue"),
  },
];
export default routes;
