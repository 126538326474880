/*
 * @author: zhanggenyuan
 * @Description:
 * @Date: 2022-06-01 10:05:43
 * @LastEditTime: 2022-06-01 10:29:22
 * @FilePath: \h5\src\router\intercept.js
 */
import router from "./index";

router.beforeEach((to, from, next) => {
  next()
});
