/*
 * @author: zhangzhaodi
 * @Description:
 * @Date: 2022-08-24 11:19:13
 * @LastEditTime: 2022-10-08 14:07:59
 * @FilePath: \sdk-h5\src\components\loading\index.js
 */

import Vue from "vue";
import loading from "@/components/loading/index.vue";

const createdLoading = Vue.extend(loading);

const instance = new createdLoading({
  el: document.createElement("div"),
});

instance.show = false;

const $loading = {
  show(tips, isDefault = true) {
    instance.show = true;
    instance.tips = tips;
    instance.isDefault = isDefault;
    document.body.appendChild(instance.$el);
  },
  hide() {
    instance.show = false;
  },
};

export default {
  install() {
    if (!Vue.$loading) {
      Vue.$loading = $loading;
    }
    Vue.mixin({
      created() {
        this.$loading = Vue.$loading;
      },
    });
  },
};
