/*
 * @author:
 * @Description:
 * @Date: 2022-08-30 09:54:27
 * @LastEditTime: 2022-09-21 13:46:08
 * @FilePath: \sdk-h5\src\router\bargain.js
 */
const routes = [
  {
    path: "/bargain/publish",
    name: "publish",
    meta: {
      title: "发布砍价助力",
      keepAlive: true,
    },
    component: () => import("@/views/bargain/publish/index.vue"),
  },
  {
    path: "/bargain/attentionDetails",
    name: "attentionDetails",
    meta: {
      title: "收集用户信息",
    },
    component: () => import("@/views/bargain/attentionDetails/index.vue"),
  },
  {
    path: "/bargain/detail",
    name: "collectInfo",
    meta: {
      title: "任务详情",
    },
    component: () => import("@/views/bargain/detail/index.vue"),
  },
];
export default routes;
