/*
 * @author: zhanggenyuan
 * @Description:
 * @Date: 2022-05-31 19:57:25
 * @LastEditTime: 2022-06-01 14:20:32
 * @FilePath: \h5\src\store\index.js
 */
import Vue from "vue";
import Vuex from "vuex";
import persistedState from "vuex-persistedstate";
Vue.use(Vuex);

const modules = {}
const files = require.context('./modules',false,/\.js/)
files.keys().forEach(file=>{  
  const filename = file.replace(/(\.\/|\.(js|ts))/g, "")
  modules[filename] = files(file).default || files(file)
})

export default new Vuex.Store({
  modules,
  plugins: [
    persistedState({
      storage: window.localStorage,
    }),
  ],
});
