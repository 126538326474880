/*
 * @author: zhanggenyuan
 * @Description:
 * @Date: 2022-06-06 16:34:26
 * @LastEditTime: 2023-01-14 17:14:34
 * @FilePath: \sdk-h5\src\utils\util.js
 */
import { Toast } from "vant";
import { shrinkImage } from "shrinkpng";

export function errorToast(code, message) {
  if (!code) {
    console.error("检查参数");
    return;
  }
  if (code != 200) {
    Toast(message);
  }
}

// 收支原因
// 收支原因（1：任务奖励（收入）2：乐币退款（收入)3：任务结余现金退款（收入）
// 4:用户任务充值（收入）5：现金提成失败退回（收入）6：任务发布支付（支出）7：用户现金提现（支出））
export function balanceReasonHandler(type) {
  switch (type) {
    case 1:
      return "赏金任务奖励";
    case 2:
      return "现金提现失败回退";
    case 3:
      return "用户现金提现";
    case 4:
      return "用户任务充值";
    case 5:
      return "引流任务发布支付";
    case 6:
      return "引流任务乐币退款";
    case 7:
      return "引流任务现金退款";
    case 8:
      return "互关任务支付";
    case 9:
      return "互关任务乐币退款";
    case 10:
      return "互关任务现金退款";
    default:
      return "赏金任务奖励";
  }
}
// 复杂数组去重
export function uniqueFunc(arr, uniId) {
  const res = new Map();
  let newArr = arr.filter(
    (item) =>
      !res.has(item[uniId] ? item[uniId] : item["id"]) &&
      res.set(item[uniId] ? item[uniId] : item["id"], 1)
  );
  return newArr;
}

/**
 * 节流
 * options:
 * leading：false 表示禁用第一次执行
 * trailing: false 表示禁用停止触发的回调
 * 那就是 leading：false 和 trailing: false 不能同时设置
 */
export function throttle(func, wait, options) {
  var timeout, context, args, result;
  var oneTime = new Date().getTime();
  var throttled = function () {
    context = this;
    args = arguments;
    var now = new Date().getTime();
    if (now - oneTime < wait) {
      // oneTime = new Date().getTime();
      func.call(context, true, true, true);
    } else {
      oneTime = new Date().getTime();
      func.call(context, true, true, false);
    }
  };
  return throttled;
}

/**
 * 添加水印
 * @param {file} 上传的图片文件
 */
export async function addWaterMarker(file) {
  // 先将文件转成img标签
  let img = await blobToImg(file);
  // console.log(img)
  return new Promise((resolve, reject) => {
    // 创建canvas画布
    let canvas = document.createElement("canvas");
    canvas.width = img.width;
    canvas.height = img.height;
    let ctx = canvas.getContext("2d");
    ctx.drawImage(img, 0, 0);
    // console.log(img.width, img.height)
    // 设置填充字号和字体，样式，这里设置字体大小根据canvas的宽度等比缩放，防止大图片生成的水印很小的问题
    // ctx.font = `${canvas.width * 0.05}px 宋体`
    // ctx.fillStyle = "red"
    // 设置右对齐
    // ctx.textAlign = 'right'
    // 在指定位置绘制文字
    // ctx.fillText('官方示例图', canvas.width - 100, canvas.height - 100)
    // ctx.fillText('官方示例图', canvas.width - 100, canvas.height - 50)
    const content = "官方示例图",
      fontSize = 80;
    let height = 400; //两个水印之间的垂直高度
    let width = 210; //两个水印之间的水平高度

    ctx.textAlign = "left"; //设置文本对齐方式
    ctx.textBaseline = "top"; //设置文本基线
    ctx.font = `${fontSize}px Microsoft Yahei`; //设置文本字体属性
    // ctx.fillStyle = "rgba(0, 0, 0, 0.35)"; //设置文本字体颜色
    ctx.fillStyle = "rgb(255,79,3)"; //设置文本字体颜色

    //在canvas画布上绘制文字 ctx.fillText(文字内容, x位置, y位置, 文本最大宽度)
    ctx.rotate(90 / 180); //文本旋转角度设置
    let i = 0,
      j = 0,
      waterMarkerWidth = content.split("").length * fontSize;
    for (i = 0; i < canvas.width / waterMarkerWidth; i++) {
      // for (i = 0; i < canvas.width / (waterMarkerWidth); i++) {
      for (j = 0; j < canvas.height / (height - 70); j++) {
        if (j == 0) {
          ctx.fillText(
            content,
            i * (waterMarkerWidth + width),
            -height,
            canvas.width
          );
        }
        ctx.fillText(
          content,
          i * (waterMarkerWidth + width),
          j * height,
          canvas.width
        );
      }
    }

    // ctx.rotate(45 / 180)//文本旋转角度设置

    // 将canvas转成blob文件返回
    // canvas.toBlob(blob => resolve(blob))
    // canvas.toDataURL("image/png");
    resolve(dataURLtoFile(canvas.toDataURL("image/png"), "image"));
  });
}

/**
 * blob转img标签
 */
function blobToImg(blob) {
  return new Promise((resolve, reject) => {
    let reader = new FileReader();
    reader.addEventListener("load", () => {
      let img = new Image();
      img.src = reader.result;
      img.addEventListener("load", () => resolve(img));
    });
    reader.readAsDataURL(blob);
  });
}

function dataURLtoFile(dataurl, filename) {
  //将base64转换为文件
  var arr = dataurl.split(","),
    mime = arr[0].match(/:(.*?);/)[1],
    bstr = atob(arr[1]),
    n = bstr.length,
    u8arr = new Uint8Array(n);
  while (n--) {
    u8arr[n] = bstr.charCodeAt(n);
  }
  return new File([u8arr], filename, { type: mime });
}

export function priceReplece(val) {
  const reg = /^(\-)*(\d+)\.(\d\d).*$/;
  return val.replace(reg, "$1$2.$3");
}

export async function imgCompress(file) {
  if (file.size < 500 * 1024) return file;
  if (file.size >= 1024 * 1024 * 20) {
    Toast("图片上传失败");
    throw "图片大于20M";
  }
  const _file = await shrinkImage(file, {
    quality: 70,
  });
  return _file;
}

//阿拉伯数字转中文数字
export function numberConvertToUppercase(num) {
  num = Number(num);
  let upperCaseNumber = [
    "零",
    "一",
    "二",
    "三",
    "四",
    "五",
    "六",
    "七",
    "八",
    "九",
    "十",
  ];
  let length = String(num).length;
  if (length == 1) {
    return upperCaseNumber[num];
  } else if (length == 2) {
    if (num == 10) {
      return upperCaseNumber[num];
    } else if (num > 10 && num < 20) {
      return "十" + upperCaseNumber[String(num).charAt(1)];
    } else {
      return (
        upperCaseNumber[String(num).charAt(0)] +
        "十" +
        upperCaseNumber[String(num).charAt(1)].replace("零", "")
      );
    }
  }
}
