/*
 * @author:
 * @Description:
 * @Date: 2022-08-30 09:54:27
 * @LastEditTime: 2022-08-30 15:11:39
 * @FilePath: \sdk-h5\src\router\bargain.js
 */
const routes = [
  {
    path: "/recharge/publish",
    name: "recharge/publish",
    meta: {
      title: "充值任务",
      keepAlive: true,
    },
    component: () => import("@/views/recharge/publish/index.vue"),
  },
];
export default routes;