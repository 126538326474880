/*
 * @author: zhangzhaodi
 * @Description:
 * @Date: 2022-08-24 09:59:09
 * @LastEditTime: 2022-10-14 11:44:21
 * @FilePath: \sdk-h5\src\utils\directive.js
 */
import Vue from "vue";
import _ from "lodash";
/**
 * @description: 用于模态框
 * @return {*}
 * @author: zhangzhaodi
 */
Vue.directive("numberOfclick", {
  bind: function (el, binding, vnode) {
    window.num = 0;
    function clickHandler(e) {
      window.num++;
      if (window.num == vnode.data.attrs.number) {
        return binding.value(false, window.num);
      }
      binding.value(true, window.num);
    }
    el.__vueNumberOfclick__ = clickHandler;
    el.addEventListener("click", clickHandler);
  },
  componentUpdated() {},
  update() {
    window.num = 0;
  },
  unbind(el, binding) {
    el.removeEventListener("click", el.__vueNumberOfclick__);
    delete el.__vueNumberOfclick__;
  },
});
/**
 * @description: 防抖
 * @return {*}
 * @author: zhangzhaodi
 */
Vue.directive("debounce2", {
  bind: function (el, binding, vnode) {
    let timer = null;
    function clickHandler() {
      if (timer) {
        clearTimeout(timer);
      }
      timer = setTimeout(() => {
        binding.value();
      }, 400);
    }
    el.__vueDebounce2__ = clickHandler;
    el.addEventListener("click", clickHandler);
  },
  unbind(el) {
    el.removeEventListener("click", el.__vueDebounce2__);
    delete el.__vueDebounce2__;
  },
});
/**
 * @description: 防抖
 * @return {*}
 * @author: zhangzhaodi
 */
Vue.directive("debounce", {
  bind: function (el, binding, vnode) {
    function clickHandler() {
      _.debounce(() => {
        binding.value();
      }, 200)();
    }
    el.__vueDebounce__ = clickHandler;
    el.addEventListener("click", clickHandler);
  },
  unbind(el) {
    el.removeEventListener("click", el.__vueDebounce__);
    delete el.__vueDebounce__;
  },
});
/**
 * @description: 节流
 * @return {*}
 * @author: zhangzhaodi
 */
Vue.directive("throttle", {
  bind: function (el, binding, vnode) {
    function clickHandler() {
      _.throttle(() => {
        binding.value();
      }, 1000);
    }
    el.__vueThrottle__ = clickHandler;
    el.addEventListener("click", clickHandler);
  },
  unbind(el) {
    el.removeEventListener("click", el.__vueThrottle__);
    delete el.__vueThrottle__;
  },
});
/**
 * @description: 节流
 * @return {*}
 * @author: zhangzhaodi
 */
Vue.directive("throttle2", {
  bind: function (el, binding, vnode) {
    let flag = true,
      timer = null;
    function clickHandler() {
      if (flag) {
        clearTimeout(timer);
        flag = false;
        timer = setTimeout(function () {
          flag = true;
        }, 1000);
        binding.value();
      }
    }
    el.__vueThrottle2__ = clickHandler;
    el.addEventListener("click", clickHandler);
  },
  unbind(el) {
    el.removeEventListener("click", el.__vueThrottle2__);
    delete el.__vueThrottle2__;
  },
});
