/*
 * @author:
 * @Description:
 * @Date: 2022-08-30 09:54:27
 * @LastEditTime: 2022-12-15 11:27:00
 * @FilePath: \sdk-h5\src\router\addAccount.js
 */
const routes = [
  {
    path: "/addAccount/accountList",
    name: "accountList",
    meta: {
      title: "账号列表",
    },
    component: () => import("@/views/addAccount/accountList/index.vue"),
  },
  {
    path: "/addAccount/detail",
    name: "accountDetail",
    meta: {
      title: "账号详情",
    },
    component: () => import("@/views/addAccount/detail/index.vue"),
  },
  {
    path: "/addAccount/adjunction",
    name: "adjunction",
    meta: {
      title: "添加平台账号",
    },
    component: () => import("@/views/addAccount/adjunction/index.vue"),
  },
];
export default routes;
