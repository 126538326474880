/*
 * @author: zhanggenyuan
 * @Description:
 * @Date: 2022-05-31 19:57:25
 * @LastEditTime: 2023-02-07 11:12:54
 * @FilePath: \sdk-h5\src\main.js
 */

import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import "./router/intercept";
import store from "./store";
import "./utils/error";
// import "./utils/debounce";
import "./styles/vant.scss";
import "./styles/common.scss";
import itemcomponentsAll from "./plugins/components/index";
import "@/utils/directive.js";
import * as filters from "@/filters/index";
Object.keys(filters).forEach((key) => Vue.filter(key, filters[key]));
// 引入Vant
import "lib-flexible/flexible";
import Vant from "vant";
import "vant/lib/index.css";

import "babel-polyfill"
import Es6Promise from 'es6-promise'
require('es6-promise').polyfill() // 在node或浏览器中支持ES6 与CommonJS
Es6Promise.polyfill()

Vue.use(Vant);

import { Lazyload } from 'vant';
Vue.use(Lazyload);
// 注册时可以配置额外的选项
Vue.use(Lazyload, {
  lazyComponent: true,
});

Vue.use(itemcomponentsAll);
import Vconsole from "vconsole";
if (process.env.VUE_APP_FLAG == "test") {
  const vConsole = new Vconsole();
  Vue.use(vConsole);
}

Vue.config.productionTip = false;

new Vue({
  router,
  store,
  render: (h) => h(App),
}).$mount("#app");
