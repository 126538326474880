const routes = [
    {
        path: "/toggle",
        name: "toggle",
        component: () => import("@/views/appletExtend/toggle.vue"),
        children: [
            {
                path: "/extend",
                name: "extend",
                component: () => import("@/views/appletExtend/extend.vue"),
            },{
                path: "/appletMy",
                name: "appletMy",
                component: () => import("@/views/appletExtend/appletMy.vue"),
            },{
                path: "/earnings",
                name: "earnings",
                component: () => import("@/views/appletExtend/appletMy/earnings.vue"),
            },
        ]
    },
    {
        path: "/novelDetails",
        name: "novelDetails",
        component: () => import("@/views/appletExtend/details/newNovelDetails.vue"),
    },{
        path: "/novelChapterDetails",
        name: "novelChapterDetails",
        component: () => import("@/views/appletExtend/details/novelChapterDetails.vue"),
    },

    {
        path: "/playletDetails",
        name: "playletDetails",
        component: () => import("@/views/appletExtend/details/newPlayletDetails.vue"),
    },{
        path: "/playletList",
        name: "playletList",
        component: () => import("@/views/appletExtend/taskList/playletList.vue"),
    },


    {
        path: "/realization",
        name: "realization",
        component: () => import("@/views/appletExtend/appletMy/realization.vue"),
    },
    {
        path: "/realizationDetails",
        name: "realizationDetails",
        component: () => import("@/views/appletExtend/details/realizationDetails.vue"),
    },{
        path: "/realizationAndExtend",
        name: "realizationAndExtend",
        component: () => import("@/views/appletExtend/details/realizationAndExtend.vue"),
    },{
        path: "/filmDetails",
        name: "filmDetails",
        component: () => import("@/views/appletExtend/details/filmDetails.vue"),
    },

    {
        path: "/rulePage",
        name: "rulePage",
        component: () => import("@/views/appletExtend/rulePage.vue"),
    },
    {
        path: "/videoPage",
        name: "videoPage",
        component: () => import("@/views/appletExtend/videoPage.vue"),
    },
    {
        path: "/ranking",
        name: "ranking",
        component: () => import("@/views/appletExtend/ranking/ranking.vue"),
    }
];
export default routes;
