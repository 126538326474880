/*
 * @author: wangchunjie
 * @Description:
 * @Date: 2022-08-11 13:50:43
 * @LastEditTime: 2022-08-23 10:04:00
 * @FilePath: \sdk-h5\src\router\follow.js
 */
const routes = [
  {
    path: "/likeTask/details",
    name: "likeTaskDetails",
    meta: {
      title: "任务详情",
    },
    component: () => import("@/views/likeTask/details/index.vue"),
  },
  {
    path: "/likeTask/billDetails",
    name: "likeTaskBillDetails",
    meta: {
      title: "账单明细",
    },
    component: () => import("@/views/likeTask/billDetails/index.vue"),
  },
  {
    path: "/likeTask/registered",
    name: "likeTaskRegistered",
    meta: {
      title: "我报名的",
    },
    component: () => import("@/views/likeTask/registered/index.vue"),
  },
  {
    path: "/likeTask/attentionDetails",
    name: "likeTaskAttentionDetails",
    meta: {
      title: "任务详情-点赞", // 更具tab展示标头
    },
    component: () => import("@/views/likeTask/attentionDetails/index.vue"),
  },
  {
    path: "/likeTask/publishFocusTasks",
    name: "likeTaskPublishFocusTasks",
    meta: {
      title: "发布关注任务",
    },
    component: () => import("@/views/likeTask/publishFocusTasks/index.vue"),
  },
  {
    path: "/likeTask/iPosted",
    name: "likeTask",
    meta: {
      title: "我发布的",
    },
    component: () => import("@/views/likeTask/iPosted/index.vue"),
  },
];

export default routes;
