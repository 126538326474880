/*
 * @author: 张兆迪
 * @Description: 全局filters
 * @Date: 2022-08-26 10:52:51
 * @LastEditTime: 2022-10-29 15:58:22
 * @FilePath: \sdk-h5\src\filters\index.js
 */
import dateFormat from "@/utils/date";
/**
 * @description: 订单状态
 * @param {*} value
 * @return {*}
 */
export function orderStatus(value) {
  // 0-待付款，1-待审核，2-审核不通过，3-进行中，4-已结束
  switch (value) {
    case 0:
      return "待付款";
    case 1:
      return "订单待审核";
    case 2:
      return "审核不通过";
    case 3:
      return "订单上架中";
    case 4:
      return "已结束";
    case 5:
      return "已暂停";
    case 6:
      return "修改订单待审核";
    case 7:
      return "修改订单审核不通过";
    default:
      return value;
  }
}
export function orderStatus2(value) {
  // 0-待付款，1-待审核，2-审核不通过，3-进行中，4-已结束
  switch (value) {
    case 0:
      return "待付款";
    case 1:
      return "订单待审核";
    case 2:
      return "审核不通过";
    case 3:
      return "订单上架中";
    case 4:
      return "已结束";
    case 5:
      return "已暂停";
    case 6:
      return "修改订单待审核";
    case 7:
      return "修改订单审核不通过";
    default:
      return value;
  }
}

export function orderRefundStatus(value) {
  // 0-未结算,1-已结算,2-结算中
  switch (value) {
    case 0:
      return "-未结算";
    case 1:
      return "-已结算";
    case 2:
      return "-未结算";

    default:
      return "";
  }
}

export function taskStatus(value) {
  // 0-申请中,1-待审核,2-待复审,3-成功,4-失败
  switch (value) {
    case 0:
      return "申请中";
    case 1:
      return "待审核";
    case 2:
      return "官方复审中";
    case 3:
      return "官方复审通过";
    case 4:
      return "官方复审不通过";
    default:
      return value;
  }
}
export function flowNodeStatus(value) {
  //
  let v = value.substr(value.lastIndexOf(">") + 1);
  switch (v) {
    case "GR1":
      return "官方复审通过";
    case "GR2":
      return "官方复审不通过";

    case "G1":
      return "官方审核通过";
    case "G2":
      return "官方审核不通过";

    case "B1":
      return "商户审核通过";
    case "B2":
      return "商户审核不通过";

    case "M3":
      return "待审核";

    case "A1":
      return "自动审核通过";

    default:
      return value;
  }
}
export function flowNodeStatus2(value) {
  //
  let v = value.substr(value.lastIndexOf(">") + 1);
  switch (v) {
    case "GR1":
      return "官方复审通过";
    case "GR2":
      return "官方复审不通过";

    case "G1":
      return "审核通过";
    case "G2":
      return "审核不通过";

    case "B1":
      return "商户审核通过";
    case "B2":
      return "商户审核不通过";

    case "M3":
      return "待审核";

    case "A1":
      return "自动审核通过";

    default:
      return value;
  }
}

/**
 * @description: 时间格式化
 * @param {*} value
 * @return {*}
 * @author: zhangzhaodi
 */
export function timeFormat(value) {
  let date;
  if (value) {
    date = new Date(value);
  } else {
    date = new Date();
  }
  let y = date.getFullYear();
  let month = date.getMonth() + 1;
  let m = month < 10 ? `0${month}` : month;
  let d = date.getDate() < 10 ? `0${date.getDate()}` : date.getDate();
  let h = date.getHours() < 10 ? `0${date.getHours()}` : date.getHours();
  let mm = date.getMinutes() < 10 ? `0${date.getMinutes()}` : date.getMinutes();
  let ss = date.getSeconds() < 10 ? `0${date.getSeconds()}` : date.getSeconds();
  return `${y}-${m}-${d} ${h}:${mm}:${ss}`;
}
export function timeFormatT(value) {
  if (!value) return value;
  return value.replace("T", " ");
}
/**
 * @description: 时间格式化
 * @param {*} time
 * @return {*}
 */
export function formatDate(time) {
  let date = new Date(time);
  return dateFormat.formatDate(date, "yyyy.MM.dd hh:mm:ss");
}

export function formatDateDelay() {
  var date = new Date(new Date().getTime() + 1000 * 60 * 60 * 24);
  let y = date.getFullYear();
  let month = date.getMonth() + 1;
  let m = month < 10 ? `0${month}` : month;
  let d = date.getDate() < 10 ? `0${date.getDate()}` : date.getDate();
  let h = date.getHours() < 10 ? `0${date.getHours()}` : date.getHours();
  let mm = date.getMinutes() < 10 ? `0${date.getMinutes()}` : date.getMinutes();
  let ss = date.getSeconds() < 10 ? `0${date.getSeconds()}` : date.getSeconds();
  return `${y}-${m}-${d} ${h}:${mm}:${ss}`;
}

export function downTime(value) {
  let day = Math.trunc(value / (24 * 60 * 60));
  let h = Math.trunc((value / (60 * 60)) % 24);
  let mm = parseInt((value % (60 * 60)) / 60);
  console.log("======", day, h, mm);
  return `${day}天${h}时${mm}分`;
}

export function numFilter(value, num) {
  let res, len
  if(String(value).indexOf(".")>-1) {
		res = value.toString().split('.')
    len = res[1].toString().split('').length
	}
  if (num !== null && String(value).indexOf(".")>-1 && num < len) {
    let sum = 1
    for (let i = 0; i < num; i++) {
      sum += '0'
    }
    Number(sum)
    return (Math.floor(value*sum)/sum).toFixed(num);
  } else {
    return value
  }
}

export function nameFilter(val) {
  if (val.length > 8) {
    return val.substr(0, 7) + '...'
  }
  return val
}